import React from "react";
import { Box, Button, Text, Title } from "../Core";
import { Col, Row } from "react-bootstrap";
import { Link } from "gatsby";
import Image from "gatsby-image";

export const MoreCardButton = ({ areaLink }) => (
    <Link to={`/areas/${areaLink}`}>
        <Box
            style={{ border: '1px solid #000' }}
            className="text-center"
            borderRadius={10}

            mt={3}
        >
            <Row>
                <Col className="col-12 m-2">
                    <Title variant="card" fontSize="17px" letterSpacing={-0.75} mb={1}>
                        View all for selected region
                    </Title>
                </Col>
            </Row>
        </Box>
    </Link>

)

export const TownCard = ({ townImg, title, link, regionName }) => (
    <Box
        style={{ border: '1px solid #ccc' }}
        className="text-center"
        borderRadius={10}
        mb={0}
    >
        <Row className="flex-center town-card-wrapper">
            {/*
                <Col xs={3} md={2} className="town-card-image-wrapper">
                    <Link to={`/cities/${link}`}>
                        <Image
                            fluid={townImg.childImageSharp.fluid}
                            style={{ borderRadius: '8px' }}
                            className="town-card-image"
                        />
                    </Link>
                </Col>
            */}

            <Col md={12} className="town-card-text-wrapper">
                <Row>
                    <Col xs={8}>
                        <div className="text-left">
                            <Link to={`/cities/${link}`}>
                                <Title variant="card" fontSize="19px" letterSpacing={-0.75} mb={1}>
                                    {title}
                                </Title>
                            </Link>
                            <Text fontSize="13px" lineHeight={1.75}>
                                <span className="locationTitle">
                                    <i className="fa fa-map-marker-alt mr-2" style={{ opacity: '0.7' }} />
                                    <span className="region-name-wrapper">
                                        {regionName}, <span className="mobile-block">United Kingdom</span>
                                    </span>
                                </span>
                            </Text>
                        </div>
                    </Col>
                    <Col xs={4} className="align-right">
                        <Link to={`/cities/${link}`} className="view-city-link">
                            <Button
                                className="text-center view-city-button"
                                variant="outline"
                            >
                                View
                            </Button>
                        </Link>
                    </Col>
                </Row>
            </Col>
        </Row>
    </Box>
);

export const BigTownCard = ({ townImg, title, children, link, ...rest }) => (
    <Box
        style={{ border: '1px solid #ccc' }}
        className="text-center"
        p="15px"
        borderRadius={10}
        mb={0}
        {...rest}
    >
        <Row>
            <Col className="col-6 col-md-8">
                <div className="text-left p-0 p-md-2">
                    <Link to={`/cities/${link}`}>
                        <Title variant="card" fontSize="24px" letterSpacing={-0.75} mb={2}>
                            {title}
                        </Title>
                    </Link>
                    <Text fontSize={2} lineHeight={1.75}>{children}</Text>
                    <Link to={`/cities/${link}`}>
                        <Button
                            className="mt-4"
                            variant="outline"
                        >
                            View
                        </Button>
                    </Link>
                </div>
            </Col>
            <Col className="col-6 col-md-4 pt-1">
                <Image
                    fluid={townImg.childImageSharp?.fluid}
                    style={{ width: '130px', height: '130px', borderRadius: '8px', float: 'right' }}
                />
            </Col>
        </Row>
    </Box>
);