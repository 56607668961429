import React from "react";
import { rgba } from "polished";
import { Container, Row, Col } from "react-bootstrap";
import { Section, Box, Text, Button } from "../../components/Core";
import styled from "styled-components";
import { graphql, Link, useStaticQuery } from "gatsby";
import YouFibreLogo from "../../assets/image/svg/yf-logo.svg"
import OutlinedLightButton from "../../components/Core/OutlinedLightButton";


const FeatureCard = ({
    color = "primary",
    iconName,
    title,
    children,
    ...rest
}) => (
    <Box
        width={"100%"}
        bg="light"
        p="30px"
        borderRadius={10}
        style={{ boxShadow: '0 6px 20px rgb(15 12 21 / 10%)' }}
        {...rest}>
        <Row className="align-items-center">
            <Col sm={12} md={1} className="pb-3 pb-md-0 ">
                <Box
                    size={69}
                    minWidth={69}
                    minHeight={69}
                    borderRadius="50%"
                    color={color}
                    fontSize="28px"
                    className="d-flex justify-content-center align-items-center"
                    mr="20px"
                    css={`
                      background-color: ${({ theme, color }) =>
                            rgba(theme.colors[color], 0.1)};
                    `}
                >
                    <i className={iconName} />
                </Box>
            </Col>
            <Col sm={12} md={10} className="pl-4 pl-md-5">
                <Text
                    color="heading"
                    as="h3"
                    fontSize="1.6em"
                    fontWeight={500}
                    letterSpacing={-0.75}
                    mb={0}
                >
                    {title}
                </Text>
                <Text fontSize={3} lineHeight={1.75}>
                    {
                        children.indexOf('YouFibre') > -1
                            ?
                            <a href="https://www.youfibre.com" target="_blank">
                                <img alt="" src={YouFibreLogo} className="mb-1" width='auto' height={24} />
                            </a>
                            : children
                    }
                </Text>
            </Col>
        </Row>
    </Box>
);

const SectionStyled = styled(Section)`
  background: linear-gradient(90deg, rgba(252, 58, 76, 1) 0%, rgba(206, 3, 144, 1) 100%);
`;

const GlobalStats = () => {

    const data = useStaticQuery(graphql`
    query getGlobalStats{
      strapiGlobalStats {
        stat_1_title
        stat_1_description
        stat_2_title
        stat_2_description
        stat_3_title
        stat_3_description
        stat_partner_description
      }
    }`)

    const {
        stat_1_title,
        stat_1_description,
        stat_2_title,
        stat_2_description,
        stat_3_title,
        stat_3_description,
        stat_partner_description
    } = data.strapiGlobalStats

    return (
        <SectionStyled>
            <Container>
                <Row className="align-items-center">
                    <Col
                        sm={12} md={9}
                        className="mx-auto"
                        data-aos="fade-bottom"
                        data-aos-duration="750"
                        data-aos-once="true"
                        data-aos-delay="50"
                    >
                        <FeatureCard
                            color="secondary"
                            iconName="fa fa-home"
                            title={stat_1_title}
                        >
                            {stat_1_description}
                        </FeatureCard>
                    </Col>

                    <Col
                        sm={12} md={9}
                        className="mt-4 mx-auto"
                        data-aos="fade-bottom"
                        data-aos-duration="750"
                        data-aos-once="true"
                        data-aos-delay="50"
                    >
                        <FeatureCard
                            color="primary"
                            iconName="fa fa-network-wired"
                            title={stat_2_title}
                        >
                            {stat_2_description}
                        </FeatureCard>
                    </Col>

                    <Col
                        sm={12} md={9}
                        className="mt-4 mx-auto"
                        data-aos="fade-bottom"
                        data-aos-duration="750"
                        data-aos-once="true"
                        data-aos-delay="50"
                    >
                        <FeatureCard
                            color="ash"
                            iconName="fa fa-handshake"
                            title={stat_3_title}
                        >
                            {stat_3_description}
                        </FeatureCard>
                    </Col>
                    <Col sm={12} className="text-center mt-5">
                        <Text color="light" className="mb-5">{stat_partner_description}</Text>
                        <Link to="/contact">
                            <OutlinedLightButton>Become a Partner</OutlinedLightButton>
                        </Link>
                    </Col>
                </Row>
            </Container>
        </SectionStyled>
    )
}

export default GlobalStats;
